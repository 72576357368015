import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PrimaryButton } from "../components/Buttons"
import styled from "styled-components"
import { Breakpoint } from "../components/breakpoints"

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${Breakpoint.small}) {
    padding-bottom: 6rem;
    align-items: flex-start;
    padding-top: 10vh;
  }
  color: black;
`

const RegistrationSection = styled.section`
  background-color: white;
  max-width: 500px;
  padding: 3rem 1rem;
  @media only screen and (min-width: ${Breakpoint.small}) {
    padding: 3rem;
    border-radius: 1em;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const Label = styled.p`
  font-size: 0.8rem;
  font-weight: bolder;
  margin-bottom: 0.5rem;
`

const Input = styled.input`
  padding: 0.6rem;
  border-radius: 0.3rem;
  max-width: 20rem;
  flex-grow: 1;
  font-size: 1.1rem;
`
const MarginLeft = styled.div`
  margin-left: 0.5rem;
`
const RegisterButton = () => (
  <MarginLeft>
    <PrimaryButton to={"/register"}>Register</PrimaryButton>
  </MarginLeft>
)

const RegisterForm = () => (
  <Column>
    <Label>Email</Label>
    <Row>
      <Input type={"email"} />
      <RegisterButton />
    </Row>
  </Column>
)

const RegistrationPage = () => (
  <Layout>
    <SEO title="Home" />
    <Section>
      <RegistrationSection>
        <h2>Register and we’ll be in touch</h2>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and.{" "}
        </p>
        <RegisterForm />
      </RegistrationSection>
    </Section>
  </Layout>
)

export default RegistrationPage
